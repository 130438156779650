<template>
  <div class="pick-unit">
    <p class="pick-unit-title">{{ titleKey }}：</p>
    <div class="pick-unit-right">
      <pick-button
        :style="{ width: `${width}px` }"
        :value="pickedText"
        :iconSRC="arrowDownSRC"
        :isActive="isShowPickList"
        @onClick="onPickButtonClick"
      ></pick-button>
      <ul class="pick-unit-right-value">
        <li v-for="(item, index) of infoArray" :key="index">
          {{ item }}
        </li>
      </ul>
      <pick-list
        class="pick-unit-right-list"
        :isShow="isShowPickList"
        :pickArray="pickArray"
        :selectedIndex="currentPickedIndex"
        @onListItemClick="onListItemClick"
      ></pick-list>
    </div>
  </div>
</template>

<script>
import PickButton from "@c/device-borrow/borrow/pick-button.vue";
import PickList from "@c/common/pick-list.vue";
import infoUnitTool from "@c/setting/organization-configuration/display/info-unit/info-unit-tool.js";

export default {
  components: {
    PickButton,
    PickList,
  },

  props: {
    titleKey: String,
    initialPickedIndex: {
      type: Number,
      default: 1,
    },
    width: {
      type: Number,
      default: 160,
    },
  },

  data() {
    return {
      arrowDownSRC: require("@imgs/triangle.png"),
      isShowPickList: false,
      currentPickedIndex: 1,
    };
  },

  created() {
    this.currentPickedIndex = this.initialPickedIndex;
  },

  watch:{
    initialPickedIndex(value){
      this.currentPickedIndex = value
    }
  },

  computed: {
    pickedText() {
      return this.pickArray[this.currentPickedIndex];
    },

    pickArray() {
      switch (this.titleKey) {
        case "儿童报告诊断标准":
          return getObjectKeys(CHILD_STANDDARD_TYPE_CODE);
        case "报告评估建议":
          return getObjectKeys(REPORT_SUGGESTION_TYPE);
      }
    },

    infoArray() {
      switch (this.titleKey) {
        case "儿童报告诊断标准":
          return infoUnitTool.standardContentArray(this.pickedText);
        case "报告评估建议":
          return infoUnitTool.suggestionContentArray(this.pickedText);
      }
    },
  },

  methods: {
    onPickButtonClick() {
      this.isShowPickList = true;
    },

    onListItemClick(selectedIndex) {
      this.isShowPickList = false;
      this.currentPickedIndex = selectedIndex;
      this.$emit("onListItemClick", this.currentPickedIndex);
    },
  },
};
</script>


<style lang="scss" scoped>

.pick-unit {
  color: black;
  font-size: rem(14);
  line-height: rem(20);
  display: flex;

  &-title {
    margin-top: rem(7);
  }

  &-right {
    position: relative;

    &-list {
      position: absolute;
      top: rem(32);
    }

    &-value {
      color: #666666;
      margin-top: rem(29);
      display: flex;
      flex-direction: column;
    }
  }
}
</style>