function standardContentArray(childStandardName) {
  let ahiDegreeArray = [];
  switch (childStandardName) {
    case "2007版中国指南":
      ahiDegreeArray = AHI_DEGREE_ARRAY.中国儿童;
      break;
    case "2020版中国指南/2014版美国指南":
      ahiDegreeArray = AHI_DEGREE_ARRAY.美国儿童;
      break;
  }
  return [
    `正常：OAHI < ${ahiDegreeArray[0]}`,
    `轻度：${ahiDegreeArray[0]} ≤ OAHI < ${ahiDegreeArray[1]}`,
    `中度：${ahiDegreeArray[1]} ≤ OAHI < ${ahiDegreeArray[2]}`,
    `重度：${ahiDegreeArray[2]} ≤ OAHI`
  ];
}

function suggestionContentArray(suggestionTypeName) {
  switch (suggestionTypeName) {
    case "智能评估":
      return ["睡眠分期分析：已开启", "睡眠呼吸综述：已开启", "建议：已开启"];
    default:
      return [];
  }
}

export default {
  standardContentArray,
  suggestionContentArray
};
